import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import BlobImageComponent from '../components/ImageWithText';
import SvgWithImage from './SvgWithImage';

// so this is what we would avoid by having proper resolvers and custom schema set up, I think it's not that bad x)
const mapProjectsToArtists = (projects) =>
  projects.reduce((acc, { node }) => {
    const slug = node.fields.slug;
    const id = node.id;
    const { title, nameOfArtists } = node.frontmatter;
    nameOfArtists?.forEach(({ artist }) => {
      if (!acc[artist]) {
        acc[artist] = [{ slug, title, id }];
      } else {
        acc[artist] = acc[artist].concat({ slug, title, id });
      }
    });
    return acc;
  }, {});

const ArtistsListTemplate = ({ data, activeNav }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [artistShown, setArtistShown] = useState('');
  useEffect(() => {
    if (activeNav) {
      closePopUp();
    }
  }, [activeNav])


  function showArtistInfo(artistName) {
    getOffset();
    setArtistShown(artistName);
  }

  function closePopUp() {
    setArtistShown('');
  }

  const getOffset = () => {
    setScrollPos(window.pageYOffset + 160);
    if (scrollPos > 260) {
      setScrollPos(prev => prev - 60);
    }
  };
  const { blogPosts: posts } = data;
  const { artists: artists } = data;

  const currentArtistShown = artists.edges.find(
    ({ node: artist }) => artist.frontmatter.artistName === artistShown
  )?.node.frontmatter;
  const artistProjectMap = mapProjectsToArtists(posts.edges);
  return (
    <React.Fragment>
      <div className="columns is-multiline is-centered">
        {artists.edges?.map(({ node: artist }, index) => (
          //grid of artist names 
          <div className="is-parent column is-4" key={artist.id}>
            <article className="columns is-mobile is-centered">
              <div>
                <div
                  className={'is-size-5 link pointer'}
                  data-target="artistInfo"
                  tabIndex={0}
                  onKeyPress={() => showArtistInfo(artist.frontmatter.artistName)}
                  onClick={() => showArtistInfo(artist.frontmatter.artistName)}
                >
                  <BlobImageComponent
                    title={artist.frontmatter.artistName}
                    index={index}
                    // subheading={relatedPostArtist}
                    width={230}
                    height={230}
                    textSize={24}
                  />
                  <span>
                    <br></br>
                  </span>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>
      {currentArtistShown && (
        //popup window for artist details
        <div>
          <div
            className='backdrop'
            onClick={() => closePopUp()}
          ></div>
          <div>
            <div
              id="artistInfo"
              className="has-text-left content overlay"
              style={{ top: scrollPos }}
            >
              <div>
                <div
                  className="is-pulled-right closeButton has-text-light is-family-primary"
                  data-target="artistInfo"
                  tabIndex={0}
                  onClick={() => closePopUp()}
                  onKeyPress={() => closePopUp()}
                >
                  X
                </div>
                <br></br>
                <br></br>
                <div className='overlay-content'>
                  <div className='columns is-centered is-8 has-text-left'>
                    <div className="column p-0 artist-image-overlay">
                      {currentArtistShown.artistImage ? (
                        <SvgWithImage
                          img={currentArtistShown.artistImage}
                          width={250}
                          height={250}
                        />
                      ) : null}
                    </div>
                    <div className="column is-8">
                      <h2>{currentArtistShown.artistName}</h2>
                      {currentArtistShown.artistPronoun && <p>({currentArtistShown.artistPronoun})</p>}
                      <div>
                        <p>{currentArtistShown.bio}</p>
                        <br></br>
                      </div>
                      <div className="columns is-multiline is-centered center-blobs-overlay">
                        {artistProjectMap[currentArtistShown.artistName]?.map((project, index) => (
                          <div className="column is-4" key={project.id}>
                            <Link className="has-text-white pointer" key={project.id} to={project.slug}>
                              <BlobImageComponent
                                title={project.title}
                                index={index}
                                // subheading={relatedPostArtist}
                                width={200}
                                height={200}
                                textSize={18}
                              />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="column"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </React.Fragment>
  );
}

ArtistsList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function ArtistsList(props) {
  return (
    <StaticQuery
      query={graphql`
        query ArtistListQuery {
          blogPosts: allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___artistName] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  nameOfArtists {
                    artist
                  }
                }
              }
            }
          }
          artists: allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___artistName] }
            filter: { frontmatter: { contentType: { eq: "artist" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  artistName
                  artistPronoun
                  bio
                  artistImage {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 200)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return <ArtistsListTemplate data={data} {...props} />;
      }}
    />
  );
}
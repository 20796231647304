import React, { Fragment, useContext, useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import ArtistsList from "../components/ArtistsList";
import Navbar from "../components/Navbar";
import Content, { HTMLContent } from "../components/Content";
import HelmetComponent from "../components/HelmetComponent";

export const ArtistsPageTemplate = ({ content, contentComponent }) => {
    const PageContent = contentComponent || Content;
    const [navIsActive, setNavIsActive] = useState(false);
    return (
        <div>
            <HelmetComponent />
            <Navbar navIsActive={navIsActive} setNavIsActive={setNavIsActive} />
            <section className="section">
                <div className="container">
                    <div className="content">
                        {/* <h1>Artists</h1> */}
                        <div className='artist-page-text'>
                            <PageContent content={content} />
                        </div>
                        <ArtistsList activeNav={navIsActive} />
                    </div>
                </div>
            </section>
        </div>
    );
}

ArtistsPageTemplate.propTypes = {
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const ArtistsPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Fragment>
            <ArtistsPageTemplate
                contentComponent={HTMLContent}
                content={post.html}
            />
        </Fragment>
    );
};

ArtistsPage.propTypes = {
    data: PropTypes.object.isRequired,
};
export default ArtistsPage;

export const artistsPageQuery = graphql`
  query ArtistsPage {
    markdownRemark(frontmatter: {templateKey: {eq: "artists-page"}}) {
        html
      }
    }
`;